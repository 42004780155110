import React from 'react'
import { graphql } from 'gatsby'
import { Col, Row, Container } from 'reactstrap'
import { articleCard } from '../components/LatestFromWikiJob/index'

const Latest = ({ data }) => (
    <Container>
        <Row>
            <Col xs={6}>
                <h4 className="px-2">Latest Articles</h4>
                {data.latest.edges.map(articleCard)}
            </Col>
            <Col xs={6}>
                <h4 className="px-2">Recently Updated</h4>
                {data.allDatoCmsArticle.edges.map(articleCard)}
            </Col>
        </Row>
    </Container>
);

export default Latest

export const query = graphql`
  query latestArticlesQuery {

    latest: allDatoCmsArticle(limit: 100, sort: {fields: meta___publishedAt, order: DESC}) {
      edges {
        node {
          title
          body
          id
          slug
          shortUrl
          category {
            id
            category {
              title
              id
              slug
            }
            title
            slug
          }
          coverImage {
            fluid(
              imgixParams: {
                auto: "compress, format, enhance"
                fit: "fill"
                w: "544"
                h: "343"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          meta {
            updatedAt
          }
          modularContent {
            ... on DatoCmsHtml {
              html
            }
            ... on DatoCmsMarkdown {
              markdownNode {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
    allDatoCmsArticle(limit: 100) {
      edges {
        node {
          title
          body
          id
          slug
          shortUrl
          category {
            id
            category {
              title
              id
              slug
            }
            title
            slug
          }
          coverImage {
            fluid(
              imgixParams: {
                auto: "compress, format, enhance"
                fit: "fill"
                w: "544"
                h: "343"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          meta {
            updatedAt
          }
          modularContent {
            ... on DatoCmsHtml {
              html
            }
            ... on DatoCmsMarkdown {
              markdownNode {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`
