import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Row, Col } from 'reactstrap'
import { StaticQuery, graphql, Link } from 'gatsby'
import cheerio from 'cheerio'
import articleUrl from 'src/utils/articleUrl'
import dayjs from 'dayjs'
import Img from 'gatsby-image'
import styles from './LatestFrom.module.scss'

const formatYear = (s) => s.replace('{YEAR}', dayjs().format('YYYY'))

const fallbackImage =
  'https://www.datocms-assets.com/7756/1600624248-wikijob-large-2.png?w=220,auto=format,compress'

const LatestFromWikiJob = () => (
  <StaticQuery
    query={graphql`
      {
        allDatoCmsArticle(limit: 4) {
          edges {
            node {
              title
              body
              id
              slug
              shortUrl
              category {
                id
                category {
                  title
                  id
                  slug
                }
                title
                slug
              }
              coverImage {
                fluid(
                  imgixParams: {
                    auto: "compress, format, enhance"
                    fit: "fill"
                    w: "544"
                    h: "343"
                  }
                ) {
                  ...GatsbyDatoCmsFluid
                }
              }
              meta {
                updatedAt
              }
              modularContent {
                ... on DatoCmsHtml {
                  html
                }
                ... on DatoCmsMarkdown {
                  markdownNode {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Row className="pt-3">
        <Col md={6} xs={12}>
          {data.allDatoCmsArticle.edges.map(firstArticle)}
        </Col>
        <Col md={6} xs={12}>
          {data.allDatoCmsArticle.edges.map(articleCard)}
        </Col>
      </Row>
    )}
  />
)

function itemImage(image) {
  return image ? (
    <Img fluid={image.fluid} className={styles.articleCoverImage} />
  ) : (
    <div
      className={styles.articleCoverImage}
      style={{ backgroundImage: `url(${fallbackImage})` }}
    />
  )
}

function firstArticle({ node: article }, index) {
  const modularHtml = ReactDOMServer.renderToStaticMarkup(
    article.modularContent.map(formatModular)
  );
  const $ = cheerio.load(article.body + modularHtml);
  let text = null;
  $("p").each((i, allNodes) => {
    if (
      allNodes.children[0] &&
      allNodes.children[0].data &&
      allNodes.children[0].data.length > 130
    ) {
      if (!text) {
        text = allNodes.children[0].data;
      }
    }
  });
  const articleDate = dayjs(article.updatedAt).format("D MMMM YYYY");

  if (index === 0) {
    return (
      <Link
        className={styles.articleLink + " " + styles.standout}
        to={articleUrl(article)}
        key={article.title + index}
      >
        <div className={styles.articleDate}>{articleDate}</div>
        <h2 className={styles.articleTitle}>{formatYear(article.title)}</h2>
        <p className={styles.articleBody}>{text && text}</p>
        {itemImage(article.coverImage)}
      </Link>
    );
  }
  return null;
}

export function articleCard({ node: article }, index) {
  const modularHtml = ReactDOMServer.renderToStaticMarkup(
    article.modularContent.map(formatModular)
  )
  const $ = cheerio.load(article.body + modularHtml)
  let text = null
  $('p').each((i, allNodes) => {
    if (
      allNodes.children[0] &&
      allNodes.children[0].data &&
      allNodes.children[0].data.length > 90
    ) {
      if (!text) {
        text = allNodes.children[0].data
      }
    }
  })

  const articleDate = dayjs(article.updatedAt).format('D MMMM YYYY')

  if (index > 0) {
    return (
      <Link
        className={styles.articleLink}
        to={articleUrl(article)}
        key={article.id}
      >
        <Row>
          <Col md={6} xs={12}>
            {itemImage(article.coverImage)}
          </Col>
          <Col md={6} xs={12}>
            <div className={styles.articleDate}>{articleDate}</div>
            <h5 className={styles.articleTitle}>{formatYear(article.title)}</h5>
            <div className={styles.articleBody}>{text && text}</div>
          </Col>
        </Row>
      </Link>
    )
  }
  return null
}

const formatModular = (item) => {
  switch (item.__typename) {
    case 'DatoCmsMarkdown':
      return renderHtml(item.markdownNode.childMarkdownRemark.html)
    case 'DatoCmsHtml':
      return renderHtml(item.html)
    default:
      return null
  }
}

const renderHtml = (html) => {
  return (
    <div
      {...{
        dangerouslySetInnerHTML: { __html: html },
      }}
    />
  )
}

export default LatestFromWikiJob
